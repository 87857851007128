import React from 'react'
import PdfTron from './PdfTron'
import { Provider } from 'react-redux'
import { store } from './store/store'

const App = () => {
  return (
    <Provider store={store} >
      <PdfTron/>
    </Provider>
  )
}

export default App