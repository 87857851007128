import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  value: 0,
  resultedImage: null,
  selectedPages:[],
  pdfTronInstance:null
}

export const pdfTronSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    setResultImage: (state, action)=>{
        if(action.payload){
            state.selectedPages = action.payload.pages
            state.resultedImage = action.payload.image
        }
    },
    setPdfTronInstance: (state, action) => {
      state.pdfTronInstance = action.payload.instance
    }
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, setResultImage, setPdfTronInstance } = pdfTronSlice.actions

export default pdfTronSlice.reducer