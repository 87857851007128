import WebViewer from "@pdftron/webviewer";
import FileSaver from "file-saver";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactCrop from 'react-image-crop';
import "./App.css";
import selectTemp from "./media/file select template.png";
import uploadFileTemplate from "./media/upload_file.png";
import Pdfjs from "./Pdfjs";
import { getXfdfData, removeXfdfData, saveXfdfData } from "./utils/localStorage/xfdfData";


const PdfTron = () => {
  const viewer = useRef(null);
  const xfdfData = getXfdfData();
  const [annotationManager, setAnnotationManager] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const convert = require("xml-js");
  const [resultedImage, setResultedImage] = useState([]);
  const [pdfSelectedFile, setPdfSelectedFile] = useState();
  const instanceRef = useRef();

  const [selectedPage, setSelectedPage] = useState()
  const [showConfirmBox, setShowConfirmBox] = useState(false)
  const [template, setTemplate] = useState();
  const [cropImageSource, setCropImageSource] = useState()
  const [crop, setCrop] = useState()
  const [imageReactCrop, setImageReactCrop] = useState()
  const [show, setShow] = useState(false);

  const changeHandler = async (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsText(files[0]);

    reader.onload = (e) => {
      setSelectedFile(e.target.result);
    };
  };

  const handleSubmission = async () => {
    var result = convert.json2xml(selectedFile);
    await annotationManager.importAnnotations(result);
  };

  
  const saveFile = (val) => {

    var result2 = convert.xml2json(val);

    const bytes = new TextEncoder().encode(result2);
    const blob = new Blob([bytes], {
      type: "application/json;charset=utf-8",
    });
    FileSaver.saveAs(blob, "test1.json");
  };

  useEffect(() => {
    WebViewer(
      {
        path: "/webviewer/lib",
        initialDoc: "/files/drawing-pdf-new.pdf",
        enableMeasurement: true,
        fullAPI: true,
        autoExpandOutlines: true,
      },
      viewer.current
    ).then(async (instance) => {
      instanceRef.current = instance;

      instance.UI.enableBookmarkIconShortcutVisibility();
      instance.UI.exportBookmarks();
      instance.enableElements(["bookmarksPanel", "bookmarksPanelButton"]);
      instance.UI.enableFeatures([instance.Feature.ThumbnailMultiselect]);

      instance.UI.loadDocument(
        "http://<documentserver>/FileDownload?docId=foo",
        {
          extension: "svg",
        }
      );

      const { Feature } = instance.UI;

      instance.UI.enableFeatures([Feature.FilePicker]);

      const { documentViewer, annotationManager, Annotations, PDFNet } = instance.Core;
      
      const annotHistoryManager = documentViewer.getAnnotationHistoryManager();

      setAnnotationManager(annotationManager);

      instance.UI.disableElements(["toolbarGroup-Forms"]);
      instance.UI.disableElements(["toolbarGroup-Shapes"]);
      instance.UI.disableElements(["toolbarGroup-Edit"]);

      instance.UI.createToolbarGroup({
        name: "Auto Count",
        dataElementSuffix: "Draw",
        useDefaultElements: false,
        children: [
          { type: "spacer" },
          {
            type: "actionButton",
            toolGroup: "ellipseAreaTools",
            img:
              '<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.955 490.955" ><path id="XMLID_448_" d="M445.767,308.42l-53.374-76.49v-20.656v-11.366V97.241c0-6.669-2.604-12.94-7.318-17.645L312.787,7.301C308.073,2.588,301.796,0,295.149,0H77.597C54.161,0,35.103,19.066,35.103,42.494V425.68c0,23.427,19.059,42.494,42.494,42.494h159.307h39.714c1.902,2.54,3.915,5,6.232,7.205c10.033,9.593,23.547,15.576,38.501,15.576c26.935,0-1.247,0,34.363,0c14.936,0,28.483-5.982,38.517-15.576c11.693-11.159,17.348-25.825,17.348-40.29v-40.06c16.216-3.418,30.114-13.866,37.91-28.811C459.151,347.704,457.731,325.554,445.767,308.42z M170.095,414.872H87.422V53.302h175.681v46.752c0,16.655,13.547,30.209,30.209,30.209h46.76v66.377h-0.255v0.039c-17.685-0.415-35.529,7.285-46.934,23.46l-61.586,88.28c-11.965,17.134-13.387,39.284-3.722,57.799c7.795,14.945,21.692,25.393,37.91,28.811v19.842h-10.29H170.095z M410.316,345.771c-2.03,3.866-5.99,6.271-10.337,6.271h-0.016h-32.575v83.048c0,6.437-5.239,11.662-11.659,11.662h-0.017H321.35h-0.017c-6.423,0-11.662-5.225-11.662-11.662v-83.048h-32.574h-0.016c-4.346,0-8.308-2.405-10.336-6.271c-2.012-3.866-1.725-8.49,0.783-12.07l61.424-88.064c2.189-3.123,5.769-4.984,9.57-4.984h0.017c3.802,0,7.38,1.861,9.568,4.984l61.427,88.064C412.04,337.28,412.328,341.905,410.316,345.771z"/></svg>',
            dataElement: "ellipseAreaToolGroupButton",
            title: "Count",
            onClick: () => {
              removeXfdfData()
              annotHistoryManager.undo();
            },
          },
          { type: "divider" },
          {
            type: "statefulButton",
            initialState: "count",
            states: {
              count: {
                number: resultedImage,
                img: selectTemp,
                onClick: (update, activeState) => {
                  activeState.number = resultedImage;
                  update();
                },
              },
            },
            title: "select template",
            onClick: () => {
              
            },
          },
          { type: "divider" },
          {
            type: "actionButton",
            toolGroup: "ellipseAreaTools",
            img:uploadFileTemplate,
            dataElement: "ellipseAreaToolGroupButton",
            title: "Select Template",
            onClick: async() => {
              // select image to crop symbols

              const doc = documentViewer.getDocument()
              const options = {  flatten: true };
              const data = await doc.getFileData(options);
              const arr = new Uint8Array(data);
                             
              setSelectedPage(documentViewer.getCurrentPage())
              setPdfSelectedFile(arr)
              handleShow()
            },
          },
          { type: "spacer", hidden: ["tablet", "mobile", "small-mobile"] },
        ],
      });

      instance.setHeaderItems((header) => {
        header.push({
          type: "actionButton",
          img:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
          onClick: async () => {
            
            saveXfdfData(
              await annotationManager.exportAnnotations({
                links: false,
                widgets: false,
              })
            );
            
            saveFile(
              await annotationManager.exportAnnotations({
                links: false,
                widgets: false,
              })
            );
          },
        });
      });

      documentViewer.setDocumentXFDFRetriever(async () => {
        // load the annotation data
      
        if(xfdfData){
          return xfdfData;
        }
      });

      documentViewer.addEventListener("documentLoaded", async () => {
        // do something after the document loads

      });
    });
  }, []);

  useEffect(() => {
    if (instanceRef.current) {
      const instance = instanceRef.current;
      

      const { Feature } = instance.UI;
      const { documentViewer, annotationManager, Annotations, PDFNet } = instance.Core;

      const annotHistoryManager = documentViewer.getAnnotationHistoryManager();

      instance.UI.createToolbarGroup({
        name: "Auto Count",
        dataElementSuffix: "Draw",
        useDefaultElements: false,
        children: [
          { type: "spacer" },
          {
            type: "actionButton",
            toolGroup: "ellipseAreaTools",
            img:
              '<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.955 490.955" ><path id="XMLID_448_" d="M445.767,308.42l-53.374-76.49v-20.656v-11.366V97.241c0-6.669-2.604-12.94-7.318-17.645L312.787,7.301C308.073,2.588,301.796,0,295.149,0H77.597C54.161,0,35.103,19.066,35.103,42.494V425.68c0,23.427,19.059,42.494,42.494,42.494h159.307h39.714c1.902,2.54,3.915,5,6.232,7.205c10.033,9.593,23.547,15.576,38.501,15.576c26.935,0-1.247,0,34.363,0c14.936,0,28.483-5.982,38.517-15.576c11.693-11.159,17.348-25.825,17.348-40.29v-40.06c16.216-3.418,30.114-13.866,37.91-28.811C459.151,347.704,457.731,325.554,445.767,308.42z M170.095,414.872H87.422V53.302h175.681v46.752c0,16.655,13.547,30.209,30.209,30.209h46.76v66.377h-0.255v0.039c-17.685-0.415-35.529,7.285-46.934,23.46l-61.586,88.28c-11.965,17.134-13.387,39.284-3.722,57.799c7.795,14.945,21.692,25.393,37.91,28.811v19.842h-10.29H170.095z M410.316,345.771c-2.03,3.866-5.99,6.271-10.337,6.271h-0.016h-32.575v83.048c0,6.437-5.239,11.662-11.659,11.662h-0.017H321.35h-0.017c-6.423,0-11.662-5.225-11.662-11.662v-83.048h-32.574h-0.016c-4.346,0-8.308-2.405-10.336-6.271c-2.012-3.866-1.725-8.49,0.783-12.07l61.424-88.064c2.189-3.123,5.769-4.984,9.57-4.984h0.017c3.802,0,7.38,1.861,9.568,4.984l61.427,88.064C412.04,337.28,412.328,341.905,410.316,345.771z"/></svg>',
            dataElement: "ellipseAreaToolGroupButton",
            title: "Remove",
            onClick: () => {
              removeXfdfData()
              annotHistoryManager.undo();
            },
          },
          { type: "divider" },
          {
            type: "statefulButton",
            initialState: "count",
            states: {
              count: {
                number: resultedImage,
                img: selectTemp,
                onClick: (update, activeState) => {
                  
                  activeState.number = resultedImage;
                  
                  const annotateArr = resultedImage.map((coordinates,index)=>{
                    // add annotations with matching coordinates
                    const pageHeight = documentViewer.getPageHeight(selectedPage)
                    const pageWidth = documentViewer.getPageWidth(selectedPage)
                    
                    const widthPercentage = (1000/pageWidth) + 0.19
                    const changedX = coordinates[0] + (coordinates[0] * widthPercentage)
                    const changedX2 = coordinates[2] + (coordinates[2] * widthPercentage)
                    const changedY = coordinates[1] + (coordinates[1] * widthPercentage)
                    const changedY2 = coordinates[3] + (coordinates[3] * widthPercentage)
                    
                    const width = changedX2 - changedX
                    const height = changedY2 - changedY
                    
                    
                    const rectangleAnnot = new Annotations.RectangleAnnotation({
                      PageNumber: selectedPage,
                      // values are in page coordinates with (0, 0) in the top left
                      X:changedX,
                      Y:changedY - 20,
                      Width: width ,
                      Height: height ,
                      StrokeColor: new Annotations.Color(255, 0, 0, 1),
                      StrokeThickness: 3 ,
                      Author: annotationManager.getCurrentUser(),
                    });
                    // add rectangleAnnotate
                    annotationManager.addAnnotation(rectangleAnnot);
                    annotationManager.redrawAnnotation(rectangleAnnot);
                  })

                  update();
                },
              },
              
            },
            title: "Count Template",
          },
          { type: "divider" },
          {
            type: "actionButton",
            toolGroup: "ellipseAreaTools",
            img:uploadFileTemplate,
            dataElement: "ellipseAreaToolGroupButton",
            title: "Select Template",
            onClick: async() => {
              // select image to crop symbols

              const doc = await documentViewer.getDocument()

              const options = {  flatten: true };
              const data = await doc.getFileData(options);
              const arr = new Uint8Array(data);
              // const blob = new Blob([arr], { type: 'application/pdf' });
      
              setSelectedPage(documentViewer.getCurrentPage())
              setPdfSelectedFile(arr)
              handleShow()
            },
          },
          { type: "spacer", hidden: ["tablet", "mobile", "small-mobile"] },
        ],
      });
      

    }

    
  }, [resultedImage]);

  const cropImageNow = async() => {
    // crop template(symbol) for auto count 

    const canvas = document.createElement('canvas');
    const scaleX = imageReactCrop.naturalWidth / imageReactCrop.width;
    const scaleY = imageReactCrop.naturalHeight / imageReactCrop.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
  
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
  
    ctx.drawImage(
      imageReactCrop,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
    // Converting to base64
    const base64Image = canvas.toDataURL();
    
    setTemplate(base64Image)
    handleClose()
    handleConfirmBoxShow()
  };

  const okayHandler=async()=>{
    // send API request to backend to get coordinates
    const fd = new FormData();
    
    fd.append("image", cropImageSource.src);
    fd.append("templateImage", template);

    const header = new Headers();
    header.append("username", "user123");
    header.append("password", "123456");

    const url = process.env.REACT_APP_AUTO_COUNT_ENDPOINT;

  const markedPoints = await fetch(url, { method: "POST", body: fd, headers: header })
   .then((res) => {
    return res.json();
  })
  .catch((err) => console.log(err));

    setResultedImage(points=>{
      return [...markedPoints.result]
    })
    handleConfirmBoxClose()
  }

  const selectCanvas = (canvas) => {
    const src = canvas.toDataURL();
    setCropImageSource({id:canvas.id ,src:src})
    handleShow()
    
  };

  const cancelHandler =()=>{
    handleConfirmBoxClose()
    handleShow()
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleConfirmBoxClose = () => setShowConfirmBox(false);
  const handleConfirmBoxShow = () => setShowConfirmBox(true);

  return (
    <Fragment>
      <div className="App">
        <div>
          {pdfSelectedFile &&(
            <Pdfjs
            selectedPage={selectedPage}
            selectCanvas={selectCanvas}
            file={pdfSelectedFile}
            />
            )}
            <div className="webviewer" ref={viewer}></div>
        </div>
      </div>
      <Modal centered show={showConfirmBox} onHide={handleConfirmBoxClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body  >
        {template && <div style={{
        display:'flex',
        alignItems:'center',
        }} >
          <span style={{fontSize:'large'}} >Are you sure you want to proceed with auto count?</span>
        <img alt="symbol(template)" width={'140px'} src={template} />

      </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={okayHandler}>
            Okay
          </Button>
          <Button variant="secondary" onClick={cancelHandler}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Symbol</Modal.Title>
        </Modal.Header>
        <Modal.Body  >
        {cropImageSource && <div style={{
        display:'flex',
        alignItems:'center',
        flexDirection:'column'
        }} >
        <ReactCrop  crop={crop} onChange={c => setCrop(c)} >
        <img alt="to crop" src={cropImageSource.src} onLoad={(i)=>setImageReactCrop(i.target)} />
        </ReactCrop>

      </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cropImageNow}>
            Crop
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default PdfTron;
