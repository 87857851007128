import React, { useEffect, useRef } from "react";

const Pdfjs = (props) => {
  const pdfRef = useRef();
  
  const renderPDf = async (url) => {
    try {
      // to render local pdf from html input {url : props.file}
      // const pdfUrl = URL.createObjectURL(props.file);
      
      pdfRef.current = await window.pdfjsLib.getDocument( {data:props.file} );
    } catch (error) {
      alert(error.message);
    }
      showPage(props.selectedPage);
  };

  const showPage = async (page_no) => {
    try {
      var page = await pdfRef.current.getPage(page_no);
    } catch (error) {
      alert(error.message);
    }
    var pdf_original_width = page.getViewport(1).width;
    var canvas = document.createElement("canvas");
    canvas.width = 1000
    // as the canvas is of a fixed width we need to adjust the scale of the viewport where page is rendered

    var scale_required = canvas.width / pdf_original_width;
    // get viewport to render the page at required scale
    var viewport = page.getViewport(scale_required);

    canvas.id = page_no;
    canvas.height = viewport.height;

    var render_context = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };

    try {
      await page.render(render_context);
    } catch (error) {
      alert(error.message);
    }
 
      props.selectCanvas(canvas);

  };
  useEffect(() => {
    renderPDf();
  }, [props.selectedPage]);

  return (
    <div style={{width:'100%'}} >
    </div>
  );
};

export default Pdfjs;
