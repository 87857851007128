const XFDF_DATA = "XFDF_DATA";



export const saveXfdfData = (data) => {
    
    localStorage.setItem(XFDF_DATA, data);
};

export const getXfdfData = () => {
    return localStorage.getItem(XFDF_DATA);
};


export const removeXfdfData = () => {
    localStorage.removeItem(XFDF_DATA);
};




